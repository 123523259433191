
import { type ValidationObserver } from "vee-validate";
import Vue, { type PropType } from "vue";
import TextInputWithValidate from "~/components/TextInputWithValidate.vue";
import AddDepartmentToDoctor from "~/components/doctor/AddDepartmentToDoctor.vue";
import { FirebaseCollections, FirebaseDocuments } from "~/repository";
import {
  type AddDepartmentToDoctorInput,
  type DoctorItem,
  type DepartmentOfDoctor,
  type EditDoctorInput,
} from "~/types/doctor";
import type * as FirebaseTypes from "~/types/firebase";
import { getSelectOptionArray } from "~/utils/common";

export default Vue.extend({
  name: "EditDoctorModal",

  components: {
    TextInputWithValidate,
    AddDepartmentToDoctor,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    doctor: {
      type: Object as PropType<DoctorItem>,
      required: true,
    },
  },

  data() {
    return {
      isOpenAddDepartmentModal: false,
      editDoctorInput: {
        name: "",
        departments: [] as AddDepartmentToDoctorInput[],
        type: "doctor",
        viewOnly: false,
      } as EditDoctorInput,
      departmentsAsName: [] as DepartmentOfDoctor[],
      departments: [] as AddDepartmentToDoctorInput[],
      selectOptions: [] as FirebaseTypes.SelectOption[],
      selectOptionsOfStaffType: [
        {
          body: "医師",
          value: "doctor",
        },
        {
          body: "看護師",
          value: "nurse",
        },
        {
          body: "Contreaアカウント",
          value: "contrea",
        },
      ] as FirebaseTypes.SelectOption[],
    };
  },

  computed: {
    isValidDepartments(): Boolean {
      return this.departments.length !== 0;
    },
  },

  watch: {
    doctor() {
      this.editDoctorInput.name = this.doctor.name;
      this.editDoctorInput.type = this.doctor.type;
      this.editDoctorInput.viewOnly = this.doctor.viewOnly ?? false;
      this.departmentsAsName = [];
      this.departments = [];

      const departments: AddDepartmentToDoctorInput[] = this.doctor.departments;

      departments.forEach(async (departmentOfDoctor) => {
        const departmentDocument = new FirebaseDocuments.DepartmentDocument(departmentOfDoctor.id);
        const department = await departmentDocument.getDepartmentData();
        if (!department) {
          return;
        }
        const departmentAndAll: DepartmentOfDoctor = {
          name: "",
          organs: [],
        };

        departmentAndAll.name = department.name.ja;

        departmentAndAll.organs = await Promise.all(
          departmentOfDoctor.organs.map(async (organAndApproach) => {
            const organDocument = new FirebaseDocuments.OrganDocument(department.id, organAndApproach.id);
            const organ = await organDocument.getOrganData();
            if (!organ) {
              return {
                name: "this organ is not found",
                approaches: [],
              };
            }

            const approaches = await Promise.all(
              organAndApproach.approaches.map(async (approachId) => {
                const approachDocument = new FirebaseDocuments.ApproachDocument(department.id, organ.id, approachId);
                const approach = await approachDocument.getApproachData();

                return approach ? (approach.name.ja as string) : "this approach is not found";
              })
            );

            return {
              name: organ.name.ja as string,
              approaches,
            };
          })
        );

        this.departmentsAsName.push(departmentAndAll);
        this.departments.push(departmentOfDoctor);
      });
    },
  },

  async created() {
    const departmentCollection = new FirebaseCollections.DepartmentCollection();
    const departments = await departmentCollection.getDepartmentData();

    this.selectOptions = getSelectOptionArray(departments);
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },

    openAddDepartmentModal() {
      this.$emit("close-modal");
      this.isOpenAddDepartmentModal = true;
    },

    closeAddDepartmentModal() {
      this.isOpenAddDepartmentModal = false;
      this.$emit("open-modal");
    },

    fetchSelectOptionsOfDepartment(): FirebaseTypes.SelectOption[] {
      return this.selectOptions;
    },

    addDepartment(addDepartmentInputOfId: AddDepartmentToDoctorInput, addDepartmentInputOfName: DepartmentOfDoctor) {
      // 重複チェック
      const duplicated = this.departments.find((val) => val.id === addDepartmentInputOfId.id);
      if (duplicated) {
        this.$store.commit("setSystemErrorMessage", "その診療科はすでに登録されています");
        return;
      }

      this.departments.push(addDepartmentInputOfId);
      this.departmentsAsName.push(addDepartmentInputOfName);
      this.closeAddDepartmentModal();
    },

    deleteDepartment(index: number) {
      this.departments.splice(index, 1);
      this.departmentsAsName.splice(index, 1);
    },

    async updateDoctor() {
      const isValid = await (this.$refs.editValidator as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        return;
      }

      this.editDoctorInput.departments = this.departments;

      this.$emit("update-event", this.doctor.id, this.editDoctorInput);
    },
  },
});
