
import Vue from "vue";
import SnackBar from "~/components/others/SnackBar.vue";
import AppBar from "@/components/others/AppBar.vue";
import CoreView from "@/components/others/CoreView.vue";
import Drawer from "@/components/others/Drawer.vue";

export default Vue.extend({
  components: {
    CoreDrawer: Drawer,
    CoreAppBar: AppBar,
    CoreView,
    SnackBar,
  },
});
