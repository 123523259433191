
import Vue from "vue";
import firebase from "~/plugins/firebase";
import { type StateAdmin } from "~/types/firebase";

export default Vue.extend({
  middleware: "authenticated",

  data() {
    return {
      password: "",
      passwordConfirm: "",
      passwordChangeModal: false,
      successAlert: false,
      alertText: "",
      user: this.$store.state.user.user as StateAdmin,
    };
  },

  methods: {
    openDialog(type: "changePassword") {
      if (type === "changePassword") {
        this.passwordChangeModal = true;
      }
    },
    async changePassword() {
      const obse: any = this.$refs.observer;
      const isValid = await obse.validate();
      if (isValid) {
        await firebase.auth().currentUser?.updatePassword(this.password);
        this.successAlert = true;
        this.alertText = "パスワードが変更されました。";
        this.passwordChangeModal = false;
      }
    },
  },
});
