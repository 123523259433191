
import Vue from "vue";

export default Vue.extend({
  name: "TaskCard",

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },

  methods: {
    buttonClicked(): void {
      this.$emit("click");
    },
  },
});
