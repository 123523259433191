import { ValidationProvider } from "vee-validate";
export default defineComponent({
  name: "TextAreaWithValidate",
  components: {
    ValidationProvider: ValidationProvider
  },
  props: {
    label: {
      default: "",
      type: String,
      required: false
    },
    value: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      required: true
    }
  },
  computed: {
    newValue: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        if (this.value !== val) {
          this.$emit("input", val);
        }
      }
    }
  }
});