import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2183c4a6&scoped=true&"
import script from "./index.vue?vue&type=script&setup=true&lang=ts&"
export * from "./index.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=2183c4a6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2183c4a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectWithValidate: require('/home/runner/work/contrea-api/contrea-api/admin/components/SelectWithValidate.vue').default})
