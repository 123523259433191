
import EasyMDE from "easymde";
import "easymde/dist/easymde.min.css";
// @ts-ignore
import { marked } from "marked/lib/marked";
import { ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Vue from "vue";
import SelectWithValidate from "~/components/SelectWithValidate.vue";
import TextInputWithValidate from "~/components/TextInputWithValidate.vue";
import type firebase from "~/plugins/firebase";
import { FirebaseCollections } from "~/repository";
import { NotificationCategoryCollection } from "~/repository/components/notification";
import type * as firebaseTypes from "~/types/firebase";
import { timestampToDisplay } from "~/utils/common";

extend("required", {
  ...required,
  message: "空欄です",
});

export default Vue.extend({
  name: "Notifications",
  components: {
    ValidationObserver,
    TextInputWithValidate,
    SelectWithValidate,
  },

  middleware: "authenticated",
  data() {
    return {
      marked,
      errorMessage: "",
      dialogAdd: false,
      dialogDetail: false,
      target: "",
      categoryId: "",
      title: "", // タイトル
      headers: [
        {
          text: "タイトル",
          value: "title",
        },
        {
          text: "対象",
          value: "customType",
        },
        {
          text: "カテゴリ",
          value: "category",
        },
        {
          text: "更新日時",
          value: "updateTimestamp",
        },
        {
          text: "詳細",
          value: "detail",
        },
      ],
      targetOptions: ["staff", "patient"],
      categoryOptions: [] as firebaseTypes.SelectOption[],
      doctorData: [] as (firebase.firestore.DocumentData & { id: string } & {
        hospitalName: string;
      })[],
      messageData: [] as (firebase.firestore.DocumentData & { id: string })[],
      details: {} as any,
      sendMessageId: "",
      user: this.$store.state.user.user,
      simplemde: null as EasyMDE | null,
    };
  },

  async created() {
    await this.init();
  },

  updated() {
    const element = document.getElementById("markdown-editor");
    if (!element || this.simplemde) {
      return;
    }

    const simplemde = new EasyMDE({
      element,
    });

    this.simplemde = simplemde;
  },

  methods: {
    async init() {
      const messageCategory = await new NotificationCategoryCollection().getCategoryData();
      this.categoryOptions = messageCategory.map((category) => ({
        value: category.id,
        body: category.name,
      }));
      const messageCollection = new FirebaseCollections.NotificationMessageCollection();
      this.messageData = await timestampToDisplay(await messageCollection.getNotificationData()).map((d) => {
        const category = messageCategory.find((category) => category.id === d.categoryId);
        return {
          ...d,
          category: category?.name,
        };
      });
      for (const tmp of this.messageData) {
        const targetCollection = new FirebaseCollections.NotificationTargetCollection(tmp.id);
        const targetData = await targetCollection.getNotificationTargetData();
        tmp.target = [];
        for (const t of targetData) {
          tmp.target.push(t.uid);
        }
      }
    },

    async getAllDoctorData() {
      this.doctorData = [];
      const hospitalCollection = new FirebaseCollections.HospitalCollection();
      const hospitalData = await hospitalCollection.getHospitalData();
      for (const hospital of hospitalData) {
        const doctorCollection = new FirebaseCollections.DoctorCollection(hospital.id);
        const doctorData = await doctorCollection.getDoctorData();
        for (const doctor of doctorData) {
          this.doctorData.push(Object.assign(doctor, { hospitalName: hospital.name.ja }));
        }
      }
    },

    closeDialogAdd() {
      this.dialogAdd = false;
    },
    openDialogAdd() {
      this.title = "";
      this.categoryId = "";
      this.target = "";
      this.dialogAdd = true;
    },

    async openDialogDetail(item: firebase.firestore.DocumentData & { id: string }) {
      const targetData = [];
      this.doctorData = [];
      await this.getAllDoctorData();
      const targetCollection = new FirebaseCollections.NotificationTargetCollection(item.id);
      const getTargetData = await targetCollection.getNotificationTargetData();
      for (const tmp of getTargetData) {
        for (const doc of this.doctorData) {
          if (tmp.uid === doc.id) {
            targetData.push({
              name: doc.name,
              hospital: doc.hospitalName,
            });
          }
        }
      }
      this.details = Object.assign(item, { target: targetData });
      this.dialogDetail = true;
    },

    closeDialogDetail() {
      this.dialogDetail = false;
    },

    async sendMessage() {
      const obse: any = this.$refs.observer;
      const isValid = await obse.validate();
      if (isValid && confirm("本当に送信しますか?")) {
        if (this.target === "staff") {
          await this.sendToAllStaff();
          await this.init();
          window.alert("送信が完了しました.");
          this.dialogAdd = false;
        }
        if (this.target === "patient") {
          const result = await this.sendToAllPatient();
          if (result.ok) {
            await this.init();
            window.alert("送信が完了しました.");
            this.dialogAdd = false;
          }
        }
      }
    },

    async sendToAllStaff() {
      const text = this.simplemde?.value();
      if (!text) {
        this.$store.commit("setSystemErrorMessage", "本文が空です.");
        return;
      }

      const sendMessageData = {
        categoryId: this.categoryId,
        title: this.title,
        text,
        customType: this.target,
      };
      const messageCollection = new FirebaseCollections.NotificationMessageCollection();
      try {
        await messageCollection.addNotificationMessageData(sendMessageData);
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
        this.dialogAdd = false;
        return;
      }
      try {
        const messageData = await messageCollection.getNotificationData();
        for (const tmp of messageData) {
          if (tmp.text === text) {
            this.sendMessageId = tmp.id;
            break;
          }
        }
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
        this.dialogAdd = false;
        return;
      }
      try {
        this.doctorData = [];
        await this.getAllDoctorData();
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
        this.dialogAdd = false;
        return;
      }
      try {
        const targetCollection = new FirebaseCollections.NotificationTargetCollection(this.sendMessageId);
        for (const tmp of this.doctorData) {
          const targetData = {
            uid: tmp.id,
            isRead: false,
          };
          await targetCollection.addNotificationTargetData(targetData);
        }
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
        this.dialogAdd = false;
      }
    },

    sendToAllPatient(): { ok: boolean } {
      // eslint-disable-next-line no-console
      console.log("send to all patients");
      window.alert("患者向けのお知らせ送信機能は現在実装されていません。");
      return { ok: false };
    },
  },
});
