
import { ValidationProvider } from "vee-validate";
import Vue from "vue";
export default Vue.extend({
  name: "SelectWithValidate",
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: [String, Array, Number],
      required: true,
    },
    rules: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    multi: {
      type: Boolean,
      default: false,
    },
  },
});
