
import { type ValidationObserver } from "vee-validate";
import Vue, { type PropType } from "vue";
import { type EditHospitalTypeFormInput } from "~/types/hospital";
// TypeForm設定用モーダル
export default Vue.extend({
  name: "EditTypeFormModal",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    hospital: {
      type: Object as PropType<{
        id: string;
        questionnaire: { video: { typeFormId: string } };
      }>,
      required: true,
    },
  },

  data() {
    return {
      editInput: {
        typeFormId: "",
      } as EditHospitalTypeFormInput,
    };
  },

  created() {
    if (!this.hospital) {
      return;
    }
    this.editInput.typeFormId = this.hospital.questionnaire.video.typeFormId;
  },

  methods: {
    closeModal() {
      this.$emit("close-event");
    },
    async submit() {
      const isValid = await (this.$refs.editObserver as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        return;
      }
      this.$emit("submit-event", this.editInput.typeFormId);
      window.alert(`正常に更新されました`);
      this.closeModal();
    },
  },
});
