
import { ValidationProvider } from "vee-validate";

export default {
  name: "NumberInputWithValidate",

  components: {
    ValidationProvider,
  },

  props: {
    label: {
      default: "",
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true,
    },
    rules: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
  },

  computed: {
    newValue: {
      get() {
        return this.value;
      },
      set(num) {
        num = parseInt(num);
        if (Number.isNaN(num)) {
          num = 0;
        }
        if (this.value !== num) {
          this.$emit("input", num);
        }
      },
    },
  },
};
