
import { type ValidationObserver } from "vee-validate";
import Vue, { type PropType } from "vue";
import { fetchComplementedParameterPath, useFetch } from "~/services/api/fetch";
import { type EditHospitalInput } from "~/types/hospital";

export default Vue.extend({
  name: "EditHospitalModal",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    hospital: {
      type: Object as PropType<{
        name: string;
        displayName: string;
        id: string;
      }>,
      required: true,
    },
  },

  data() {
    return {
      editHospitalInput: {
        name: {
          ja: "",
        },
        displayHospitalName: {
          ja: "" as string,
        },
      } as EditHospitalInput,
    };
  },

  created() {
    if (!this.hospital) {
      return;
    }
    this.editHospitalInput.name.ja = this.hospital.name;
    this.editHospitalInput.displayHospitalName.ja = this.hospital.displayName;
  },

  methods: {
    closeModal() {
      this.$emit("close-event");
    },
    async edit() {
      const isValid = await (this.$refs.editObserver as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        return;
      }

      const { fetch } = useFetch();

      try {
        const path = fetchComplementedParameterPath("/v1/admin/hospital/{hospitalId}/updateHospitalName", {
          hospitalId: this.hospital.id,
        });
        await fetch(path, {
          method: "put",
          body: {
            data: {
              hospitalName: this.editHospitalInput.name.ja,
              displayHospitalName: this.editHospitalInput.displayHospitalName?.ja,
            },
          },
        });
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
        return;
      }

      window.alert("正常に更新されました");
      this.$emit("close-event");
      this.$emit("close-and-transition-event");
    },
  },
});
