
import { ValidationObserver } from "vee-validate";
import Vue from "vue";
import { type components } from "~/../@types/generated/schema";
import SelectWithValidate from "~/components/SelectWithValidate.vue";
import TextInputWithValidate from "~/components/TextInputWithValidate.vue";
import AddDepartmentToDoctor from "~/components/doctor/AddDepartmentToDoctor.vue";
import ConfirmDoctorInfoModal from "~/components/doctor/ConfirmDoctorInfoModal.vue";
import type firebase from "~/plugins/firebase";
import { FirebaseCollections, FirebaseDocuments } from "~/repository";
import { useFetch } from "~/services/api/fetch";
import {
  type AddDepartmentToDoctorInput,
  type DepartmentOfDoctor,
  type DisplayConfirmDoctorInfo,
} from "~/types/doctor";
import type * as FirebaseTypes from "~/types/firebase";
import { getSelectOptionArray } from "~/utils/common";

export default Vue.extend({
  name: "CreateDoctorComponent",

  components: {
    ValidationObserver,
    TextInputWithValidate,
    SelectWithValidate,
    AddDepartmentToDoctor,
    ConfirmDoctorInfoModal,
  },

  data() {
    return {
      user: this.$store.state.user.user as FirebaseTypes.StateAdmin,
      hospitalData: [] as FirebaseTypes.DocumentDataWithId[],
      doctorData: [] as (firebase.firestore.DocumentData & {
        id: string;
        hospitalName: string;
        hospitalId: string;
      })[],
      selectOptionsOfHospital: [] as FirebaseTypes.SelectOption[],
      selectOptionsOfStaffType: [
        {
          body: "医師",
          value: "doctor",
        },
        {
          body: "看護師",
          value: "nurse",
        },
        {
          body: "Contreaアカウント",
          value: "contrea",
        },
      ] as FirebaseTypes.SelectOption[],
      selectOptions: [] as FirebaseTypes.SelectOption[],
      isOpenModal: false,
      isOpenConfirmInfoModal: false,
      createDoctorInput: {
        name: "",
        hospitalId: "",
        staffType: "",
        email: "",
        viewOnly: false,
        departments: [] as AddDepartmentToDoctorInput[],
        departmentsAsName: [] as DepartmentOfDoctor[],
      },
      info: {
        loginUrl: "",
        email: "",
        initialPassword: "",
      } as DisplayConfirmDoctorInfo,
    };
  },

  computed: {
    // TODO: データベースから参照する必要がある
    isValidEmail(): Boolean {
      const found = this.doctorData.find((doctor) => doctor.doctorCode === this.createDoctorInput.email);
      if (found) {
        window.alert("そのメールアドレスは現在使われています");
        return false;
      }
      return true;
    },

    isValidDepartments(): Boolean {
      return this.createDoctorInput.departments.length !== 0;
    },
  },

  async created() {
    await this.fetchHospitalData();
    this.selectOptionsOfHospital = getSelectOptionArray(this.hospitalData);

    await this.fetchDoctorData();

    const departmentCollection = new FirebaseCollections.DepartmentCollection();
    const departments = await departmentCollection.getDepartmentData();

    this.selectOptions = getSelectOptionArray(departments);
  },

  methods: {
    async getHospitalCode(id: string) {
      const hospitalDocument = new FirebaseDocuments.HospitalDocument(id);
      return (await hospitalDocument.getHospitalData()).hospitalCode;
    },

    async fetchHospitalData() {
      const hospitalCollection = new FirebaseCollections.HospitalCollection();
      this.hospitalData = await hospitalCollection.getHospitalData();
      this.hospitalData = this.hospitalData.filter((hospital) => hospital.status !== "deleted");
    },

    async fetchDoctorData() {
      for (const tmp of this.hospitalData) {
        const doctorCollection = new FirebaseCollections.DoctorCollection(tmp.id);
        const res = await doctorCollection.getDoctorData();
        for (const tmp2 of res) {
          this.doctorData.push({
            ...tmp2,
            hospitalName: tmp.name.ja,
            hospitalId: tmp.id,
          });
        }
      }
    },

    openModal() {
      this.isOpenModal = true;
    },

    closeModal() {
      this.isOpenModal = false;
    },

    closeConfirmModal() {
      this.isOpenConfirmInfoModal = false;
    },

    fetchSelectOptionsOfDepartment() {
      return this.selectOptions;
    },

    addDepartment(addDepartmentInputOfId: AddDepartmentToDoctorInput, addDepartmentInputOfName: DepartmentOfDoctor) {
      // 重複チェック
      const duplicated = this.createDoctorInput.departments.find((val) => val.id === addDepartmentInputOfId.id);
      if (duplicated) {
        this.$store.commit("setSystemErrorMessage", "その診療科はすでに登録されています");
        return;
      }

      this.createDoctorInput.departments.push(addDepartmentInputOfId);
      this.createDoctorInput.departmentsAsName.push(addDepartmentInputOfName);
      this.isOpenModal = false;
    },

    async createDoctor() {
      // ref: https://vee-validate.logaretm.com/v3/guide/forms.html#programmatic-access-with-refs
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid || !this.isValidDepartments) {
        return;
      }

      const { fetch } = useFetch();
      const res = await fetch("/v1/admin/hospital/staff/createStaffAccount", {
        method: "post",
        body: {
          data: {
            hospitalId: this.createDoctorInput.hospitalId,
            email: this.createDoctorInput.email,
            departments: this.createDoctorInput.departments,
            type: this.createDoctorInput.staffType as components["schemas"]["staffRole"],
            name: this.createDoctorInput.name,
            viewOnly: this.createDoctorInput.viewOnly,
          },
        },
      });
      if (!res.data) {
        this.$store.commit(
          "setSystemErrorMessage",
          "スタッフの作成に失敗しました。初めからやり直してください。同じ診療科を複数登録していないかの確認をしてください。"
        );
        return;
      }

      this.info.loginUrl = this.$config.loginUrl;
      this.info.email = this.createDoctorInput.email;
      this.info.initialPassword = res.data.initialPassword;

      this.isOpenConfirmInfoModal = true;
    },
  },
});
