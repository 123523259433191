
import Vue, { type PropType } from "vue";
import { type DoctorItem, type DepartmentOfDoctor } from "~/types/doctor";

export default Vue.extend({
  name: "DetailDoctorModal",

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    doctor: {
      type: Object as PropType<DoctorItem>,
      required: true,
    },

    departments: {
      type: Array as PropType<DepartmentOfDoctor[]>,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },

    openEditModal() {
      this.$emit("close-modal");
      this.$emit("open-edit-modal");
    },

    deleteDoctor() {
      if (!window.confirm("削除しますか?")) {
        return;
      }

      this.$emit("delete-event", this.doctor.id);
    },
    archiveDoctor() {
      if (!window.confirm("アーカイブしますか?（あとからもとに戻せます）")) {
        return;
      }

      this.$emit("archive-event", this.doctor.id);
      this.$emit("close-modal");
    },
    activateDoctor() {
      if (!window.confirm("アクティブにしますか?")) {
        return;
      }

      this.$emit("activate-event", this.doctor.id);
      this.$emit("close-modal");
    },
  },
});
