
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },

    playing: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      player: null,
    };
  },

  watch: {
    options: function () {
      if (!this.player) {
        return;
      }
      this.player.pause();
      this.player.hasStarted(false);
      this.player.currentTime(0);

      this.player.poster(this.options.poster ?? null);
      this.player.src(this.options.sources ?? {});
    },

    playlist(val) {
      if (val.length === 0) {
        return;
      }
      this.player.playlist(this.playlist);
      this.player.playlist.autoadvance(0);
    },

    playing() {
      if (!this.playing) {
        this.player.pause();
      }
      if (this.playing) {
        this.player.play();
      }
    },
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },

  methods: {
    pause() {
      this.player.pause();
    },
    initialize() {
      this.player = videojs(this.$refs.videoPlayer, this.options);
      this.player.on("ended", this.handleVideoEnded); // 動画終了時のイベントをハンドリング
    },
    handleVideoEnded() {
      // 動画が終了したらposter画面を表示するようにする
      if (this.player.isFullscreen()) {
        this.player.exitFullscreen();
      }
      this.player.hasStarted(false);
      this.player.currentTime(0);
    },
  },
};
