
import { ValidationObserver } from "vee-validate";
import Vue, { type PropType } from "vue";
import SelectWithValidate from "~/components/SelectWithValidate.vue";
import { FirebaseCollections, FirebaseDocuments } from "~/repository";
import { type AddDepartmentToDoctorInput, type DepartmentOfDoctor } from "~/types/doctor";
import type * as FirebaseTypes from "~/types/firebase";
import { getSelectOptionArray } from "~/utils/common";

export default Vue.extend({
  name: "AddDepartmentToDoctor",

  components: {
    ValidationObserver,
    SelectWithValidate,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    options: {
      type: Array as PropType<FirebaseTypes.SelectOption[]>,
      required: true,
    },
  },

  data() {
    return {
      user: this.$store.state.user.user as FirebaseTypes.StateAdmin,
      isOpenedSelectOrganAndApproaches: true,
      currentStep: 1,
      selectOptionsOfOrgan: [] as FirebaseTypes.SelectOption[],
      selectOptionsOfApproach: [] as FirebaseTypes.SelectOption[],
      temporaryOrganId: "",
      temporaryApproachIds: [] as string[],
      createDepartmentAndEtcInputAsName: {
        name: "",
        organs: [],
      } as DepartmentOfDoctor,
      createDepartmentAndEtcInput: {
        id: "",
        organs: [],
      } as AddDepartmentToDoctorInput,
    };
  },

  watch: {
    async "createDepartmentAndEtcInput.id"() {
      this.createDepartmentAndEtcInput.organs = [];
      if (!this.createDepartmentAndEtcInput.id) {
        return;
      }
      const departmentDocument = new FirebaseDocuments.DepartmentDocument(this.createDepartmentAndEtcInput.id);
      const department = (await departmentDocument.getDepartmentData())!;
      this.createDepartmentAndEtcInputAsName.name = department.name.ja;
      const organCollection = await new FirebaseCollections.OrganCollection(this.createDepartmentAndEtcInput.id);
      this.selectOptionsOfOrgan = getSelectOptionArray(await organCollection.getOrganData());
    },

    async temporaryOrganId() {
      if (!this.temporaryOrganId) {
        return;
      }
      const approachCollection = await new FirebaseCollections.ApproachCollection(
        this.createDepartmentAndEtcInput.id,
        this.temporaryOrganId
      );
      this.selectOptionsOfApproach = getSelectOptionArray(await approachCollection.getApproachData());
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },

    toNext() {
      this.currentStep += 1;
    },

    async addOrganAndApproaches() {
      const organDocument = new FirebaseDocuments.OrganDocument(
        this.createDepartmentAndEtcInput.id,
        this.temporaryOrganId
      );
      const organ = (await organDocument.getOrganData())!;

      const approachCollection = await new FirebaseCollections.ApproachCollection(
        this.createDepartmentAndEtcInput.id,
        this.temporaryOrganId
      );
      const approaches = await approachCollection.getApproachData();
      const createdDepartmentAndEtcInputAsNameOfApproaches = approaches
        .map((approach) => {
          if (this.temporaryApproachIds.includes(approach.id)) {
            return approach.name.ja;
          }
          return null;
        })
        .filter((v): v is string => !!v); // ref: https://qiita.com/suin/items/cda9af4f4f1c53c05c6f

      const isValid = !this.createDepartmentAndEtcInput.organs.find((val) => val.id === this.temporaryOrganId);

      if (!isValid) {
        this.$store.commit(
          "setSystemErrorMessage",
          "臓器が既に追加されています。同じ臓器を複数追加することはできません。"
        );
        return;
      }

      this.createDepartmentAndEtcInputAsName.organs.push({
        name: organ.name.ja,
        approaches: createdDepartmentAndEtcInputAsNameOfApproaches,
      });

      this.createDepartmentAndEtcInput.organs.push({
        id: this.temporaryOrganId,
        approaches: this.temporaryApproachIds,
      });

      this.temporaryOrganId = "";
      this.temporaryApproachIds = [];
      this.selectOptionsOfApproach = [];
      this.isOpenedSelectOrganAndApproaches = false;
    },

    deleteOrganAndApproaches(i: number) {
      this.createDepartmentAndEtcInput.organs.splice(i, 1);
      this.createDepartmentAndEtcInputAsName.organs.splice(i, 1);
    },

    addDepartments() {
      this.$emit("add-event", this.createDepartmentAndEtcInput, this.createDepartmentAndEtcInputAsName);
      this.createDepartmentAndEtcInput = {
        id: "",
        organs: [],
      };
      this.createDepartmentAndEtcInputAsName = {
        name: "",
        organs: [],
      };
      this.currentStep = 1;
      this.isOpenedSelectOrganAndApproaches = true;
    },
  },
});
