
// Utilities
import { extend } from "vee-validate";
import { required, confirmed, min } from "vee-validate/dist/rules";
import Vue from "vue";
import firebase from "~/plugins/firebase";
import { type StateAdmin } from "../../types/firebase";

extend("required", {
  ...required,
  message: "空欄です.",
});

extend("confirmed", {
  ...confirmed,
  message: "パスワードが異なります",
});

extend("min", {
  ...min,
  message: "６文字以上で入力してください",
});

export default Vue.extend({
  data() {
    return {
      password: "",
      passwordConfirm: "",
      responsive: false,
      dialogUser: false,
      dialogChangePassword: false,
      user: this.$store.state.user.user as StateAdmin,
    };
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  methods: {
    onClick() {
      this.$store.commit("setDrawer", 1);
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },

    async logout() {
      await firebase.auth().signOut();
      this.$store.commit("user/logout");
      this.$router.push("/login");
    },

    openChangeModal() {
      this.dialogUser = false;
      this.dialogChangePassword = true;
    },

    async change() {
      const obse: any = this.$refs.observer;
      const isValid = await obse.validate();
      if (isValid) {
        await firebase.auth().currentUser?.updatePassword(this.password);
        window.alert("パスワードが変更されました.");
        this.dialogChangePassword = false;
      }
    },
  },
});
