
import { extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Vue from "vue";
import TextInputWithValidate from "~/components/TextInputWithValidate.vue";
import { FirebaseCollections } from "~/repository";
import { useFetch } from "~/services/api/fetch";
import type * as firebaseTypes from "~/types/firebase";
import { type AddHospitalInput, type HospitalData } from "~/types/hospital";
import { Color } from "~/utils/style";

extend("required", {
  ...required,
  message: "空欄です.",
});

const __default__ = Vue.extend({
  components: {
    ValidationObserver,
    TextInputWithValidate,
  },
  middleware: "authenticated",
  data() {
    return {
      isLoading: false,
      user: this.$store.state.user.user as firebaseTypes.StateAdmin,
      isOpeningDialog: false,
      hospitalData: [] as HospitalData[],
      headers: [
        {
          text: "病院名",
          align: "start",
          sortable: "false",
          value: "name.ja",
        },
        {
          text: "病院ID",
          value: "hospitalCode",
        },
        {
          text: "病院追加日時",
          value: "addTimestamp",
        },
        {
          text: "病院更新日時",
          value: "updateTimestamp",
        },
        {
          text: "設定",
          value: "edit",
          sortable: false,
        },
      ],
      addedHospital: {
        name: {
          ja: "",
        },
        displayHospitalName: {
          ja: "",
        },
        hospitalCode: "",
        isTest: false,
      } as AddHospitalInput,
      colorMain: Color.main.primary,
      colorHeaderBackground: Color.black.tertiary,
      colorTab: Color.black.secondary,
    };
  },

  computed: {
    activeHospitalData(): HospitalData[] {
      return this.hospitalData.filter((hospital) => hospital.status !== "archived" && hospital.status !== "deleted");
    },

    archivedHospitalData(): HospitalData[] {
      return this.hospitalData.filter((hospital) => hospital.status === "archived");
    },
  },

  async created() {
    try {
      const hospitalCollection = new FirebaseCollections.HospitalCollection();
      this.hospitalData = await hospitalCollection.getHospitalData();
    } catch (e) {
      this.$store.commit("setSystemErrorMessage", e);
    }
  },

  methods: {
    openDialog() {
      this.addedHospital.name.ja = "";
      this.addedHospital.hospitalCode = "";
      this.isOpeningDialog = true;
    },

    closeModal() {
      this.isOpeningDialog = false;
    },

    transition(id: string) {
      this.$router.push(`/v2/hospital/detail?id=${id}`);
    },

    async save(addedHospital: AddHospitalInput) {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        return;
      }

      this.isLoading = true;

      const { fetch } = useFetch();
      const res = await fetch("/v1/admin/hospital/createHospital", {
        method: "post",
        body: {
          data: {
            hospitalCode: addedHospital.hospitalCode,
            hospitalName: addedHospital.name.ja,
            displayHospitalName: addedHospital.displayHospitalName?.ja,
          },
        },
      });
      if (!res.data) {
        if (res.status !== 200) {
          window.alert("IDが重複しています");
        } else {
          window.alert("エラーが発生しました");
        }
        this.isLoading = false;
        return;
      }

      try {
        this.hospitalData = await new FirebaseCollections.HospitalCollection().getHospitalData();
        window.alert("病院が正常に追加されました.");
        this.isOpeningDialog = false;
      } catch (e) {
        this.isOpeningDialog = false;
        this.$store.commit("setSystemErrorMessage", e);
      }

      this.isLoading = false;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "61d425b7": (_vm.colorTab),
  "4ea53852": (_vm.colorMain),
  "569b28b9": (_vm.colorHeaderBackground)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__