
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    snackbar: false,
  }),

  computed: {
    systemErrorMessage(): unknown {
      const message: Error = this.$store.getters.getSystemErrorMessage;
      if (!message) {
        return "";
      }
      this.closeSnackbar();
      this.openSnackbar();
      return message;
    },
  },

  methods: {
    resetSnackbar() {
      this.$store.commit("setSystemErrorMessage", "");
      this.closeSnackbar();
    },
    openSnackbar() {
      this.snackbar = true;
    },
    closeSnackbar() {
      this.snackbar = false;
    },
  },
});
