
import VueQrcode from "@chenfengyuan/vue-qrcode";
import Vue from "vue";

export default Vue.extend({
  name: "QRModal",
  components: {
    VueQrcode,
  },
  props: {
    hospitalName: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    qrUrl: {
      type: String,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-event");
    },
  },
});
