
import Vue, { type PropType } from "vue";
import { type DisplayConfirmDoctorInfo } from "~/types/doctor";

export default Vue.extend({
  name: "ConfirmDoctorInfoModal",

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    info: {
      type: Object as PropType<DisplayConfirmDoctorInfo>,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },

    async copyToClipBoard() {
      const text = `
        ログインURL: ${this.info.loginUrl}\n
        email: ${this.info.email}\n
        初期パスワード: ${this.info.initialPassword}
      `;
      try {
        await navigator.clipboard.writeText(text);
        window.alert("正常にクリップボードにコピーしました");
        this.closeModal();
      } catch (e) {
        this.$store.commit("setSystemErrorMessage", e);
      }
    },
  },
});
